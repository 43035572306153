<template>
    <div>
        <div v-show="message || isUserHR" class="alert alert-info">
            <span v-if="message">
                {{ message }}
            </span>
            <span v-else-if="isUserHR">
                HR: Add a time clock message here
            </span>
            <span v-if="isUserHR && !showNewMessage">
                <button class="btn btn-default" @click="showEdit">
                    Edit
                </button>
            </span>
        </div>
        <div v-if="showNewMessage">
            <div class="form-group">
                <label>New Time Clock Message</label>
                <input class="form-control" type="text" v-model="newMessage"/>
            </div>
            <div class="form-group">
                <button class="btn btn-default" @click="edit">Save</button>
                <button class="btn btn-default" @click="hideEdit">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Tool.store";

    export default {
        name: "HRMessage",
        props: ['message'],
        data() {
            return {
                newMessage: null,
                showNewMessage: false
            }
        },
        methods: {
            showEdit: function () {
                this.showNewMessage = true;
            },
            hideEdit: function () {
                this.showNewMessage = false;
                this.newMessage = null;
            },
            edit: function () {
                store.api('/cdi_settings/time_clock_message', {'params[value]': this.newMessage});
                this.$emit('newMessage', this.newMessage);
                this.hideEdit();
            }
        },
        computed: {
            isUserHR: function () {
                return this.$appStore.hasRole(this.$cdiVars.CDI_ROLE_HR);
            }
        }
    }
</script>

<style scoped>

</style>