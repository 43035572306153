<template>
    <div>
        <h3>Misc Task</h3>
        <form @submit.prevent="log" class="form-inline">
            <div class="form-group">
                <label>ID #</label>
                <input class="form-control" type="text" v-model="userID" />
            </div>
            <div class="form-group">
                <select class="form-control" v-model="taskID">
                    <option v-for="[taskID, task] in tasks" v-bind:key="taskID" :value="taskID">{{ task }}</option>
                </select>
            </div>
            <div class="form-group">
                <label>
                    <input type='radio' value=1 v-model="type">&nbsp;
                    Start
                </label>
            </div>
            <div class="form-group">
                <label>
                    <input type='radio' value=0 v-model="type">&nbsp;
                    Stop
                </label>
            </div>
            <div class="form-group">
                <button class="btn btn-default">Log</button>
            </div>
        </form>

        <Modal v-if="showWorkStoppageDialog" heading="Work Stoppage" @close="showWorkStoppageDialog = false">
            <template v-slot:body>
                <p>Please use the following form to tell us about the circumstances for this stoppage:</p>
                <a @click="showWorkStoppageDialog = false" href="https://docs.google.com/a/cdicurbs.com/forms/d/1thx0GBPUBnX16vurso3CRm9Gl9rSW2NVtZ4TlGfhSW4/viewform" target="_blank">Go to Form</a>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { store } from "@/store/Tool.store";
    import taskCache from "@/cache/task.cache";
    import Modal from "@/components/utils/Modal";

    export default {
        name: "MiscTask",
        components: {Modal},
        data() {
            return {
                userID: null,
                taskID: 28,
                type: 1,
                showWorkStoppageDialog: false
            }
        },
        methods: {
            reset: function () {
                this.taskID = 28;
                this.type = 1;
                this.userID = null;
            },
            log: function () {
                let options = {
                    'params[user_id]': this.userID,
                    'params[task_id]': this.taskID,
                    'params[start]': this.type,
                };

                store.api('/mycdi/start_task', options);

                // If it is a work stoppage submission, display a dialog prompting them to fill out an external form
                if (this.taskID === 228) {
                    this.showWorkStoppageDialog = true;
                }

                this.reset();
            }
        },
        computed: {
            tasks: function () {
                // Sort alphabetically
                return taskCache.MISC_TASKS.sort(function (a, b) {
                    if (a[1] < b[1]) {
                        return -1;
                    }

                    if (a[1] > b[1]) {
                        return 1;
                    }

                    return 0;
                });
            }
        }
    }
</script>

<style scoped>

</style>