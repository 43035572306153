<template>
    <div>
        <h3>Recognizing CDI's Values</h3>
        <form @submit.prevent="save" class="row">
            <div class="form-group col-xs-6 col-sm-4">
                <label>Employee</label>

                <Typeahead ref="nominatedUser" cdi_type="employee" v-bind:ID.sync="nominatedUserID" />
            </div>
            <div class="form-group col-xs-6 col-sm-4">
                <label>Value Exhibited</label>
                <select class="form-control" v-model="valueID">
                    <option v-for="[id, type] in values" v-bind:key="id" :value="id">{{ type }}</option>
                </select>
            </div>
            <div class="form-group col-xs-6 col-sm-4">
                <label>Submitted by</label>

                <Typeahead ref="nominatingUser" cdi_type="employee" v-bind:ID.sync="nominatingUserID" />
            </div>
            <div class="form-group col-xs-12">
                <label>Customer or CDI Benefit</label>
                <textarea class="form-control vertical-resize" rows="5" v-model="customerBenefit"></textarea>
            </div>

            <div class="form-group col-xs-12">
                <button class="btn btn-default">Recognize</button>
            </div>
        </form>
    </div>
</template>

<script>
    import { store } from "@/store/Tool.store";
    import userCache from "@/cache/user.cache.js";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Recognition",
        components: {Typeahead},
        data() {
            return {
                cache: {
                    userCache,
                },
                nominatedUserID: null,
                nominatingUserID: null,
                valueID: 371,
                customerBenefit: null
            }
        },
        methods: {
            resetData: function () {
                this.nominatedUserID = null;
                this.valueID = 371;
                this.nominatingUserID = null;
                this.customerBenefit = null;
                this.$refs.nominatedUser.$data.object = null;
                this.$refs.nominatingUser.$data.object = null;
            },
            save: function () {
                let options = {
                    'params[nominated_user_id]': this.nominatedUserID,
                    'params[nominating_user_id]': this.nominatingUserID,
                    'params[value_id]': this.valueID,
                    'params[customer_benefit]': this.customerBenefit
                };

                store.api('/mycdi/save_recognition', options);
                this.resetData();
            }
        },
        computed: {
            values: function () {
                let typesToStrip = [
                    221,
                    222,
                    223,
                    224,
                    225
                ];

                return this.cache.userCache.RECOGNITION_VALUES.filter(type => !typesToStrip.includes(type[0]));
            }
        }
    }
</script>

<style scoped>

</style>