export default {
    MISC_TASKS: [
        [425,'Assisting Current Department'],
        [168, 'Assisting In Other Departments'],
        [169, 'Audits'],
        [24,'Cleaning'],
        [426, 'Continuous Improvement Task'],
        [23, 'Equipment Maintenance'],
        [199,'Inventory'],
        [26, 'Meeting'],
        [29, 'Research and Development'],
        [427, 'Rework Task'],
        [428, 'Safety Task'],
        [27, 'Training'],
        [228, 'Work Stoppage']
    ],

    SHOP_TASKS: [
        [26, 'Sales Checking'],
        [30, 'Special Materials'],
        [22, 'Engineering'],
        [25, 'Eng Checking'],
        [21, 'Batching'],
        [11, 'Turret'],
        [17, 'Folding'],
        [33, 'Clinching'],
        [5,  'Shell'],
        [29, 'Guts'],
        [24, 'QC'],
        [7,  'Insulate'],
        [8,  'Crate'],
        [14, 'Loading'],
        [23, 'Rework'],
        [28, 'Call Ahead']
    ],

    JOBCOSTING_TASKS: [
        [22, 'Engineering'],
        [25, 'Checking'],
        [21, 'Batching'],
        [11, 'Turret'],
        [31, 'Radan'],
        [17, 'Folding'],
        [33, 'Clinching'],
        [5, 'Shell'],
        [29, 'Guts'],
        [7, 'Insulate'],
        [8, 'Crate'],
        [14, 'Loading'],
        [24, 'QualityControl'],
        [0, 'Shipped'],
        [23, 'Rework']
    ]
}
