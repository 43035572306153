<template>
    <div>
        <h4><strong>{{ title }}</strong></h4>
        <table v-if="showPoints" class="table table-responsive">
            <thead>
            <tr>
                <th>Reason</th>
                <th class="number">Count</th>
                <th class="number">Points</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="points in pointsValue" :key="points.absence_reason_id">
                <td>{{ points.absence_reason }}</td>
                <td class="number">{{ points.count }}</td>
                <td class="number">{{ points.point_accrual }}</td>
            </tr>
            </tbody>
            <thead>
            <tr>
                <th>Total</th>
                <th class="number">{{ pointsSum }}</th>
                <th class="number">{{ pointsAccrualSum }}</th>
            </tr>
            </thead>
        </table>
    </div>
</template>

<script>
import DateHelpers from "@/store/DateHelpers";
import {store} from "@/store/Tool.store";
import dateHelpers from "@/store/DateHelpers";

export default {
    props: {
        title: {
            type: String,
            default: 'Attendance Points',
            required: false
        },
        userID: {
            required: true,
        },
        startDate: {
            type: String,
            required: false,
            default: DateHelpers.getFirstOfTheYear()
        },
        endDate: {
            type: String,
            required: false,
            default: DateHelpers.getToday()
        }
    },
    data() {
        return {
            pointsValue: [],
            showPoints: false,
        }
    },
    computed: {
        pointsSum() {
            return this.pointsValue.reduce((sum, obj) => sum + obj.count, 0);
        },
        pointsAccrualSum() {
            return this.pointsValue.reduce((sum, obj) => sum + obj.point_accrual, 0);
        }
    },
    methods: {
        load: function(userID) {
            const options = {
                'params[user_id]': userID,
                'params[start_date]': dateHelpers.getFirstOfTheYear(),
                'params[end_date]': this.endDate
            };
        store.api('/timecard/get_points', options)
                .then(data => {
                    this.pointsValue = data.data;
                    this.showPoints = true;
                });
        }
    },
    watch: {
        userID: function(userID) {
            if (parseInt(userID) >= 0) {
                this.load(userID);
            }
        }
    }
}
</script>
