<template>
    <div>
        <h3>Time Clock</h3>
        <form class="form-inline" @submit.prevent="getTimecard">
            <div class="form-group">
                <label>Enter your Employee ID</label>
                <input type="text" class="form-control" v-model="lookupID" />
            </div>
            <div class="form-group">
                <button class="btn btn-default">Get Timecard</button>
            </div>
        </form>

        <div v-show="currentClockedEmployee" class="panel panel-default panel-body spacing_top">
            <p>
                Hello <strong>{{ this.currentClockedEmployee }}</strong> You are currently <span class="text-danger"><strong>Clocked {{ userClockStatus }}</strong></span>
            </p>

            <div v-show="showQuestion">
                <h4>How satisfied are you with your job?</h4>
                <p>Satisfaction is <b>anonymous</b> and asked only once a week.</p>

                <ul class='simple_list'>
                    <li>
                        <label>
                            <input type='radio' value=1 v-model="moraleAnswer">&nbsp;
                            I hate working here.
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type='radio' value=2 v-model="moraleAnswer">&nbsp;
                            I'm getting tired of my job and I am thinking about looking for other work.
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type='radio' value=3 v-model="moraleAnswer">&nbsp;
                            It's fine.
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type='radio' value=4 v-model="moraleAnswer">&nbsp;
                            I am happy coming to work each day.
                        </label>
                    </li>
                    <li>
                        <label>
                            <input type='radio' value=5 v-model="moraleAnswer">&nbsp;
                            I am VERY happy. CDI is one of the best companies I have worked for.
                        </label>
                    </li>
                </ul>

                <div v-show="moraleAnswer && moraleAnswer <= 3" class="alert alert-danger">
                    How can CDI do better? Please submit a suggestion on the My CDI page.
                </div>

                <ConfirmBox
                    v-if="showConfirmHateWork"
                    heading='Confirmation'
                    body='Please confirm you intended to check, "I hate working here."'
                    @confirm="confirmHateWork"
                />
            </div>

            <button @click="updateClockStatus(false)" :disabled="showQuestion && !moraleAnswer" v-if="this.currentlyClocked" class="btn btn-default">
                Clock me out
            </button>
            <button @click="updateClockStatus(false)" :disabled="showQuestion && !moraleAnswer" v-else class="btn btn-default">
                Clock me in
            </button>

            <button class="btn btn-default" @click="resetData">Cancel</button>
        </div>

        <div v-show="showClockedStatus" class="alert spacing_top">
            You are Clocked {{ userClockStatus }} @ {{ currentClockTime }}

            <AttendanceTable :title="'Attendance Points YTD'" :userID="currentClockedEmployeeID" />

            <div v-show="moraleFeedback && moraleFeedbackReply" class="spacing_top">
                <strong>Your Comment:</strong> {{ moraleFeedback }}
                <br>
                <strong>CDI Reply:</strong> {{ moraleFeedbackReply }}
            </div>

            <div v-show="recognition.has_recognition" class="spacing_top">
                <strong>You've been recognized by</strong> {{ recognition.nominating_user }} for {{ recognition.value }}
                <br>
                <strong>How you were recognized</strong> {{ recognition.customer_benefit }}
            </div>

            <br>

            <button @click="resetData" class="btn btn-default">Close</button>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Tool.store";
    import ConfirmBox from "@/components/utils/ConfirmBox";
    import AttendanceTable from "@/components/tools/TimeClock/AttendanceTable.vue";

    export default {
        name: "ClockInOut",
        components: {
            AttendanceTable,
            ConfirmBox
        },
        data() {
            return {
                lookupID: null,
                showClockedStatus: null,
                showQuestion: false,
                currentlyClocked: null,
                currentClockedEmployee: null,
                currentClockedEmployeeID: null,
                currentClockTime: null,
                moraleAnswer: null,
                showConfirmHateWork: false,
                recognition: [],
                moraleFeedback: null,
                moraleFeedbackReply: null,
            }
        },
        methods: {
            getTimecard: function () {
                this.showClockedStatus = null;

                if (!this.lookupID) {
                    this.$appStore.errorBox('Must enter an employee ID.');

                    return;
                }

                store.api('/mycdi/time_clock_status/', {'params': this.lookupID}).then(result => {
                    if (typeof result === 'undefined') {
                        return;
                    }

                    if (!result.valid_ip) {
                        this.$appStore.errorBox('You CANNOT Clock in from this connection');

                        return;
                    }

                    this.lookupID = null;
                    this.showQuestion = result.question;
                    this.currentlyClocked = result.clocked;
                    this.currentClockedEmployeeID = result.user_id;
                    this.currentClockedEmployee = result.employee;
                });
            },
            updateClockStatus: function (force) {
                let options = {
                    'params[morale]': this.moraleAnswer,
                    'params[user_id]': this.currentClockedEmployeeID
                };

                this.showConfirmHateWork = parseInt(this.moraleAnswer) === 1;

                if (this.showConfirmHateWork && !force) {
                    return;
                }

                store.api('/mycdi/time_clock', options).then(result => {
                    if (typeof result === 'undefined') {
                        return;
                    }

                    this.resetData();

                    this.moraleFeedbackReply = result.morale.has_feedback ? result.morale.feedback_reply : null;
                    this.moraleFeedback = result.morale.has_feedback ? result.morale.feedback : null;

                    this.currentlyClocked = result.clocked;
                    this.currentClockTime = result.current_time;

                    this.recognition = result.recognition;
                    this.showClockedStatus = true;
                });
            },
            updateClockStatusForce: function () {
                this.updateClockStatus(true);
            },
            resetData: function () {
                this.showQuestion = false;
                this.currentlyClocked = null;
                this.currentClockedEmployee = null;
                this.currentClockedEmployeeID = null;
                this.moraleAnswer = null;
                this.showConfirmHateWork = false;
                this.recognition = false;
                this.moraleFeedback = null;
                this.moraleFeedbackReply = null;
                this.currentClockTime = null;
                this.showClockedStatus = false;
            },
            confirmHateWork: function (confirm) {
                if (!confirm) {
                    this.showConfirmHateWork = false;

                    return;
                }

                this.updateClockStatusForce();
            }
        },
        computed: {
            userClockStatus: function () {
                return this.currentlyClocked ? 'In' : 'Out';
            },
        }
    }
</script>

<style scoped>

</style>